import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import cn from 'classnames'

import styles from './ArrowButtons.module.css'

type ArrowButtonsProps = {
  onClickLeft?: () => void
  onClickRight?: () => void
  leftDisabled?: boolean
  rightDisabled?: boolean
  size?: 'small' | 'medium'
  dark?: boolean
}

const ArrowButtons = ({
  onClickLeft = () => null,
  onClickRight = () => null,
  leftDisabled = false,
  rightDisabled = false,
  size = 'medium',
  dark = false,
}: ArrowButtonsProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div
        className={cn(
          styles.arrowButton,
          {
            [styles.disabled]: leftDisabled,
            [styles.dark]: dark,
          },
          styles[size],
        )}
        onClick={() => !leftDisabled && onClickLeft()}
        role="button"
        tabIndex={0}
      >
        <span className={styles.arrowText}>
          <LeftOutlined />
        </span>
      </div>
      <div style={{ width: size === 'medium' ? 8 : 5 }} />
      <div
        className={cn(
          styles.arrowButton,
          {
            [styles.disabled]: rightDisabled,
            [styles.dark]: dark,
          },
          styles[size],
        )}
        onClick={() => !rightDisabled && onClickRight()}
        role="button"
        tabIndex={0}
      >
        <span className={styles.arrowText}>
          <RightOutlined />
        </span>
      </div>
    </div>
  )
}

export default ArrowButtons
