import { ApolloError, gql, QueryHookOptions, useQuery } from '@apollo/client'
import type {
  GetProfileCollectionQuery,
  GetProfileCollectionQueryVariables,
  PageInfo,
  PublicProfileCollection,
} from 'apollo-gql'
import dotPropImmutable from 'dot-prop-immutable'
import publicProfileCollectionFragment from 'graphql/fragments/publicProfileCollectionFragment'
import { currentMarketIdVar } from 'graphql/reactive'

export const PUBLIC_PROFILE_COLLECTION_VARS = {
  first: 24,
}

export const GET_PUBLIC_PROFILE_COLLECTION = gql`
  query GetProfileCollection(
    $marketId: ID!
    $slug: String!
    $first: Int
    $skip: Int
  ) {
    market(id: $marketId) {
      id
      ...publicProfileCollectionFragment
    }
  }
  ${publicProfileCollectionFragment}
`

type ResponseProps = {
  profileCollection: PublicProfileCollection
  loading: boolean
  error: ApolloError
  loadMore: () => void
  pageInfo: PageInfo
}

type ArgsProps = {
  page?: string
  afterLoadMore?: () => void
}

export default function usePublicProfileCollection(
  slug: string,
  vars: {
    first?: number
    skip?: number
  } = {},
  options: QueryHookOptions<
    GetProfileCollectionQuery,
    GetProfileCollectionQueryVariables
  > = {},
  args?: ArgsProps,
): ResponseProps {
  const marketId = currentMarketIdVar()
  const { page, afterLoadMore } = args || {}
  const skip =
    vars?.skip ||
    (Number(page) - 1) * (vars?.first || PUBLIC_PROFILE_COLLECTION_VARS.first)

  const variables = {
    slug,
    marketId,
    ...PUBLIC_PROFILE_COLLECTION_VARS,
    ...vars,
    ...(!!page && {
      skip,
    }),
  }
  const dotPropPath = 'market.profileCollection'
  const dotPropPathConnection = `${dotPropPath}.profileProducts`

  const {
    loading,
    data = {},
    error,
    fetchMore,
  } = useQuery(GET_PUBLIC_PROFILE_COLLECTION, {
    variables,
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  const profileCollection = dotPropImmutable.get(data, dotPropPath)
  const pageInfo = dotPropImmutable.get(data, dotPropPathConnection)?.pageInfo
  const pageNumber = pageInfo?.pageNumber

  const loadMore = async () => {
    const fetchMoreVariables = {
      ...variables,
      first: variables.first,
      skip: pageNumber * variables.first,
    }

    fetchMore({
      variables: fetchMoreVariables,
    })

    afterLoadMore()
  }

  return { profileCollection, loading, error, loadMore, pageInfo }
}
