import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import type { PublicProfileCollection, PublicProfileProduct } from 'apollo-gql'
import dotPropImmutable from 'dot-prop-immutable'
import { currentMarketIdVar } from 'graphql/reactive'
import { useTranslation } from 'lib/i18n'

import ProfileCollectionLink from 'components/ProfileCollectionLink/ProfileCollectionLink'
import ProfileSidescroll from 'components/ProfileSidescroll/ProfileSidescroll'
import Wrapper from 'components/Wrapper/Wrapper'
import { GET_PUBLIC_PROFILE_COLLECTION } from 'hooks/usePublicProfileCollection'

import styles from './ProfileCollectionSidescroll.module.css'

type ProfileCollectionSidescrollProps = {
  title?: React.ReactNode | string
  profileCollection: PublicProfileCollection
  loading?: boolean
  linkPath?: string
  stripped?: boolean
  noMoreLink?: boolean
  indexPositionFromTop?: number
  prioritizeImages?: boolean
}

const ProfileCollectionSidescroll: React.FC<
  ProfileCollectionSidescrollProps
> = ({
  title = 'More profiles',
  profileCollection,
  loading = false,
  linkPath = '',
  stripped = false,
  noMoreLink = false,
  prioritizeImages = false,
  indexPositionFromTop,
}) => {
  const pageSize = 3
  const { t } = useTranslation()

  const profileProductsEdges = useMemo(
    () => profileCollection?.profileProducts?.edges || [],
    [profileCollection],
  )

  const initialProfileProducts = useMemo(
    () =>
      profileProductsEdges.map((edge) => edge.node) as PublicProfileProduct[],
    [profileProductsEdges],
  )

  const totalCount = useMemo(
    () => profileCollection?.profileProducts?.totalCount || 0,
    [profileCollection],
  )

  const defaultRemainingCount = useMemo(
    () => totalCount - profileProductsEdges.length,
    [totalCount, profileProductsEdges],
  )

  const initialPageSize = initialProfileProducts.length

  const [profileProducts, setProfileProducts] = useState(initialProfileProducts)
  const [skip, setSkip] = useState(initialPageSize)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [totalRemainingCount, setTotalRemainingCount] = useState<number>(
    defaultRemainingCount,
  )

  const updateCollectionState = useCallback(
    (newCollection: PublicProfileCollection) => {
      const edges = newCollection?.profileProducts?.edges || []
      const newProfileProducts = edges.map(
        (edge) => edge.node,
      ) as PublicProfileProduct[]

      setProfileProducts([...profileProducts, ...newProfileProducts])

      setTotalRemainingCount(totalRemainingCount - pageSize)
    },
    [profileProducts, totalRemainingCount],
  )

  const marketId = currentMarketIdVar()

  const [loadMore, { data: newCollection }] = useLazyQuery(
    GET_PUBLIC_PROFILE_COLLECTION,
    {
      variables: {
        marketId,
        first: pageSize,
        skip,
        slug: profileCollection.slug,
      },
    },
  )

  useEffect(() => {
    if (loadMoreLoading && newCollection) {
      setLoadMoreLoading(false)
      const newProfileCollection = dotPropImmutable.get(
        newCollection,
        'market.profileCollection',
      )
      updateCollectionState(newProfileCollection)
    }
  }, [
    newCollection,
    updateCollectionState,
    loadMoreLoading,
    setLoadMoreLoading,
  ])

  const onLoadMore = useCallback(async () => {
    if (profileProducts.length < 15) {
      loadMore()
      setSkip(skip + 3)
      setLoadMoreLoading(true)
    }
  }, [profileProducts, setSkip, loadMore, skip, setLoadMoreLoading])

  return (
    <Wrapper edgeToEdge>
      <ProfileSidescroll
        totalCount={totalCount}
        loadMorePlaceholder={profileProducts.length < 15}
        loading={loading}
        collectionSlug={profileCollection.slug}
        indexPositionFromTop={indexPositionFromTop}
        prioritizeImages={prioritizeImages}
        loadMore={onLoadMore}
        disableArrows={loadMoreLoading}
        title={
          <ProfileCollectionLink
            linkPath={linkPath}
            profileCollectionSlug={profileCollection.slug}
          >
            <a data-cy="profileCollectionTitle">
              <h2>{title || profileCollection.slug}</h2>
            </a>
          </ProfileCollectionLink>
        }
        lastCard={
          <ProfileCollectionLink
            linkPath={linkPath}
            profileCollectionSlug={profileCollection.slug}
          >
            <a className={styles.emptyCard}>
              <div className={styles.logo} />
              <span className={styles.browseTalentsText}>
                {t('ProfileSidescroll.browse', 'Browse')}
              </span>
              <span className={styles.browseTalentsText}>
                {t('ProfileSidescroll.moreTalents1', {
                  defaultValue: '+{{count}} talent',
                  defaultValue_plural: '+{{count}} talents',
                  count: totalRemainingCount,
                })}
              </span>
            </a>
          </ProfileCollectionLink>
        }
        moreLink={
          !noMoreLink && (
            <ProfileCollectionLink
              linkPath={linkPath}
              passHref
              profileCollectionSlug={profileCollection.slug}
            >
              <a
                className={styles.showAll}
                data-cy="profileCollectionShowAllButton"
              >
                {t('ProfileSidescroll.showAll', 'Show all')}
              </a>
            </ProfileCollectionLink>
          )
        }
        profileProducts={profileProducts}
        stripped={stripped}
      />
    </Wrapper>
  )
}

export default ProfileCollectionSidescroll
