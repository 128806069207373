import React, { useRef } from 'react'
import type { PublicProfileProduct, RecommendedPageQuery } from 'apollo-gql'
import classNames from 'classnames'

import Heading from 'components/Heading/Heading'
import HorizontalScrollButtons from 'components/HorizontalScrollButtons/HorizontalScrollButtons'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import Spinner from 'components/Spinner/Spinner'

import styles from './ProfileSidescroll.module.css'

export const MIN_NUMBER_OF_PROFILES_ON_A_ROW = 7

type ProfileSidescrollProps = {
  title: React.ReactNode
  moreLink?: React.ReactNode
  profileProducts:
    | PublicProfileProduct[]
    | RecommendedPageQuery['market']['recommendedProfileProducts']
  loading?: boolean
  disableArrows?: boolean
  totalCount?: number
  lastCard?: React.ReactNode
  stripped?: boolean
  collectionSlug?: string
  indexPositionFromTop?: number
  prioritizeImages?: boolean
  loadMore?: () => void
  loadMorePlaceholder?: boolean
}

const ProfileSidescroll: React.FC<ProfileSidescrollProps> = ({
  title,
  moreLink,
  profileProducts,
  loading,
  disableArrows,
  totalCount = profileProducts.length,
  lastCard,
  stripped = false,
  collectionSlug,
  indexPositionFromTop,
  prioritizeImages = false,
  loadMore,
  loadMorePlaceholder = false,
}) => {
  const scrollableWrapperRef = useRef<HTMLDivElement>()

  const remainingCount = totalCount - profileProducts.length

  const hasMoreThanOneRow = totalCount > MIN_NUMBER_OF_PROFILES_ON_A_ROW

  const getFirstPositionInCollection = (positionFromTop: number) =>
    positionFromTop * MIN_NUMBER_OF_PROFILES_ON_A_ROW - 6

  return (
    <section className={styles.wrapper}>
      {!stripped && (
        <header className={styles.header} data-cy="profileSidescrollHeader">
          <Heading level="h3">{title}</Heading>

          <div className={styles.headerActions}>
            {hasMoreThanOneRow && (
              <div className={styles.scrollButtonsWrapper}>
                <HorizontalScrollButtons
                  size="small"
                  scrollableWrapperRef={scrollableWrapperRef}
                  scrollStep={340}
                  onRightArrowClick={loadMore}
                  disableArrows={disableArrows}
                />
              </div>
            )}
            {hasMoreThanOneRow && moreLink}
          </div>
        </header>
      )}

      <div
        className={classNames(styles.scrollWrapper)}
        ref={scrollableWrapperRef}
      >
        {loading && totalCount === 0 && (
          <>
            <div className={styles.cardWrapper}>
              <div className={styles.loadingCard} />
            </div>
            <div className={styles.cardWrapper}>
              <div className={styles.loadingCard} />
            </div>
          </>
        )}

        {profileProducts.map((profileProduct, index) => (
          <div
            key={(profileProduct as PublicProfileProduct).id}
            className={styles.cardWrapper}
          >
            <ProfileCard
              profileProduct={profileProduct}
              stripped={stripped}
              collectionSlug={collectionSlug}
              indexPositionInCollection={index}
              startIndexPositionInCollection={getFirstPositionInCollection(
                indexPositionFromTop,
              )}
              prioritizeImage={prioritizeImages}
            />
          </div>
        ))}

        {loadMorePlaceholder && (
          <div className={styles.loadMorePlaceholder}>
            <Spinner centered />
          </div>
        )}

        {remainingCount > 0 && !stripped && lastCard && (
          <div className={styles.cardWrapperLast}>{lastCard}</div>
        )}
      </div>
    </section>
  )
}

export default ProfileSidescroll
