import { gql } from '@apollo/client'

import cardProfileProductFragment from './cardProfileProductFragment'

const publicProfileCollectionFragment = gql`
  fragment publicProfileCollectionFragment on Market {
    profileCollection(slug: $slug) {
      id
      slug
      mainTag {
        id
        childrenHasPlacement
        childrenTags {
          visibleInNavigation
          profileCollection {
            slug
            publicCmsItem {
              title
              color
            }
            profileProducts {
              totalCount
            }
          }
        }
        profileCollection {
          slug
          publicCmsItem {
            title
            color
          }
        }
        visibleInNavigation
        parentTag {
          id
          profileCollection {
            slug
            publicCmsItem {
              title
              color
            }
          }
          visibleInNavigation
          parentTag {
            id
            profileCollection {
              slug
              publicCmsItem {
                title
                color
              }
            }
            visibleInNavigation
            parentTag {
              id
              profileCollection {
                slug
                publicCmsItem {
                  title
                  color
                }
              }
              visibleInNavigation
            }
          }
        }
      }
      publicCmsItem {
        id
        content
        ... on PublicCmsItemProfileCollectionContent {
          linkPath
          title
          description
          color
          seoContent
          altTitle
          additionalText
          showOnProfile
          metaTitle
        }
      }
      profileProducts(first: $first, skip: $skip) {
        totalCount
        pageSize
        pageInfo {
          hasNextPage
          pageNumber
        }
        edges {
          node {
            ...cardProfileProductFragment
          }
        }
      }
    }
  }
  ${cardProfileProductFragment}
`

export default publicProfileCollectionFragment
