import React from 'react'

import Link from 'components/Link/Link'

export default function ProfileCollectionLink({
  children,
  linkPath,
  profileCollectionSlug,
  passHref = false,
  scroll = true,
}) {
  const moreLinkProps = linkPath
    ? {
        href: linkPath,
      }
    : {
        href: `/profiles/${profileCollectionSlug}`,
      }

  return (
    <Link
      {...moreLinkProps}
      passHref={passHref}
      scroll={scroll}
      prefetch={false}
    >
      {children}
    </Link>
  )
}
