import React, { MutableRefObject, useCallback, useState } from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import ArrowButtons from 'components/ScrollArrows/ArrowButtons'
import useEffectOnce from 'hooks/useEffectOnce'

import styles from './HorizontalScrollButtons.module.css'

type HorizontalScrollButtonsProps = {
  scrollableWrapperRef: MutableRefObject<HTMLDivElement>
  scrollStep: number
  size?: 'small' | 'medium'
  dark?: boolean
  disableArrows?: boolean
  onRightArrowClick?: () => void
}

export default function HorizontalScrollButtons({
  scrollableWrapperRef,
  scrollStep,
  size = 'medium',
  dark = false,
  onRightArrowClick,
  disableArrows,
}: HorizontalScrollButtonsProps): JSX.Element {
  const [leftDisabled, setLeftDisabled] = useState(true)
  const [rightDisabled, setRightDisabled] = useState(false)

  useEffectOnce(() => {
    if (scrollableWrapperRef?.current) {
      const { scrollWidth, offsetWidth } = scrollableWrapperRef.current
      if (scrollWidth > offsetWidth) {
        // element requires scroll
        smoothscroll.polyfill()
      }
    }
  })

  const onClickArrows = useCallback(
    ({ direction }: { direction: -1 | 1 }) => {
      if (onRightArrowClick && direction === 1) {
        onRightArrowClick()
      }

      if (scrollableWrapperRef?.current) {
        const { scrollWidth, offsetWidth, scrollLeft } =
          scrollableWrapperRef.current

        scrollableWrapperRef.current.classList.toggle(styles.overFlowX)

        scrollableWrapperRef.current.scrollBy({
          left: direction * scrollStep,
          behavior: 'smooth',
        })

        setTimeout(() => {
          scrollableWrapperRef.current.classList.toggle(styles.overFlowX)
        }, 500)

        if (scrollLeft + direction * scrollStep >= scrollWidth - offsetWidth) {
          setRightDisabled(true)
          setLeftDisabled(false)
        } else if (scrollLeft + direction * scrollStep <= 0) {
          setLeftDisabled(true)
          setRightDisabled(false)
        } else {
          setRightDisabled(false)
          setLeftDisabled(false)
        }
      }
    },
    [scrollableWrapperRef, scrollStep, onRightArrowClick],
  )

  return (
    <div className={styles.arrowsWrapper}>
      <ArrowButtons
        onClickLeft={() => {
          onClickArrows({ direction: -1 })
        }}
        onClickRight={() => {
          onClickArrows({ direction: 1 })
        }}
        leftDisabled={leftDisabled || disableArrows}
        rightDisabled={rightDisabled || disableArrows}
        size={size}
        dark={dark}
      />
    </div>
  )
}
